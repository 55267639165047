// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component30Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center bg-primary_color  fixed top-0 left-0 safearea-top z-40"}     > <FontAwesomeIcon  id='bloc0_0'  icon={ fa.faLongArrowLeft}      className={ "text-white p-4"}  />
          <div id='bloc0_1'  className={ "font-bold text-2xl text-white"}     >{`Titre Page`} </div>
          <img  id='bloc0_2' src="/profiles/ploy17/user.png" className={ "w-10 h-10 rounded-xl m-2   object-cover"}     alt="undefined" /></div><div id='bloc1'  className={ "w-full safearea-top p-10 opacity-0 text-2xl "}     >{`header`} </div><div id='bloc2'  className={ "w-screen flex flex-col items-start h-screen"}     > <div id='bloc2_0'  className={ "mt-10 w-full p-1 flex flex-row "}     > <div id='bloc2_0_0'  className={ "flex flex-row items-end"}     > <img  id='bloc2_0_0_0' src="undefined" className={ "w-12 h-12    object-cover"}     alt="undefined" />
              <FontAwesomeIcon  id='bloc2_0_0_1'  icon={ fa.faCameraAlt}      className={ " p-1 rounded-full -ml-4 bg-primary_color bg-orange-400 w-3 h-3 text-white border border-white"}  /></div>
            <div id='bloc2_0_1'  className={ "flex flex-col px-2 items-start"}     > <div id='bloc2_0_1_0'  className={ "font-bold"}     >{`Borkat Ullah`} </div>
              <div id='bloc2_0_1_1'  className={ "text-xs"}     >{`Compte Professionnel`} </div></div></div>
          <div id='bloc2_1'  className={ "p-4 w-full text-left font-bold bg-zinc-100"}     >{`Données personnelles`} </div>
          <div id='bloc2_2'  className={ "flex flex-row p-4 w-full justify-between items-center border-b border-zinc-100"}     > <div id='bloc2_2_0'  className={ "flex  "}     > <FontAwesomeIcon  id='bloc2_2_0_0'  icon={ fa.faUserShield}        />
              <div id='bloc2_2_0_1'  className={ "ml-3"}     >{`État civil`} </div></div>
            <FontAwesomeIcon  id='bloc2_2_1'  icon={ fa.faAngleRight}        /></div>
          <div id='bloc2_3'  className={ "flex flex-row p-4 w-full justify-between items-center border-b border-zinc-100"}     > <div id='bloc2_3_0'  className={ "flex  "}     > <FontAwesomeIcon  id='bloc2_3_0_0'  icon={ fa.faUserShield}        />
              <div id='bloc2_3_0_1'  className={ "ml-3"}     >{`État civil`} </div></div>
            <FontAwesomeIcon  id='bloc2_3_1'  icon={ fa.faAngleRight}        /></div>
          <div id='bloc2_4'  className={ "flex flex-row p-4 w-full justify-between items-center border-b border-zinc-100"}     > <div id='bloc2_4_0'  className={ "flex  "}     > <FontAwesomeIcon  id='bloc2_4_0_0'  icon={ fa.faUserShield}        />
              <div id='bloc2_4_0_1'  className={ "ml-3"}     >{`État civil`} </div></div>
            <FontAwesomeIcon  id='bloc2_4_1'  icon={ fa.faAngleRight}        /></div>
          <div id='bloc2_5'  className={ "flex flex-row p-4 w-full justify-between items-center border-b border-zinc-100"}     > <div id='bloc2_5_0'  className={ "flex  "}     > <FontAwesomeIcon  id='bloc2_5_0_0'  icon={ fa.faUserShield}        />
              <div id='bloc2_5_0_1'  className={ "ml-3"}     >{`État civil`} </div></div>
            <FontAwesomeIcon  id='bloc2_5_1'  icon={ fa.faAngleRight}        /></div>
          <div id='bloc2_6'  className={ "p-4 w-full text-left font-bold bg-zinc-100"}     >{`Données personnelles`} </div>
          <div id='bloc2_7'  className={ "flex flex-row p-4 w-full justify-between items-center border-b border-zinc-100"}     > <div id='bloc2_7_0'  className={ "flex  "}     > <FontAwesomeIcon  id='bloc2_7_0_0'  icon={ fa.faUserShield}        />
              <div id='bloc2_7_0_1'  className={ "ml-3"}     >{`État civil`} </div></div>
            <FontAwesomeIcon  id='bloc2_7_1'  icon={ fa.faAngleRight}        /></div>
          <div id='bloc2_8'  className={ "p-4 w-full text-left font-bold bg-zinc-100"}     >{`Données personnelles`} </div></div><div id='bloc3'  className={ " flex centre bg-primary_color fixed bottom-0 left-0 w-screen z-50 text-white safearea-bottom w-full  items-center justify-center"}     > <div id='bloc3_0'  className={ "pt-1 flex flex-row w-full justify-evenly pb-8"}     > <div id='bloc3_0_0x0'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc3_0_0x0_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc3_0_0x0_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc3_0_0x1'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc3_0_0x1_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc3_0_0x1_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc3_0_0x2'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc3_0_0x2_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc3_0_0x2_1'  className={ "text-xs"}     >{`Lien`} </div></div></div></div></>
  
}

export default Component30Page;
