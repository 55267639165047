// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component28Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-screen h-screen flex flex-col items-end overflow-hidden p-16 relative justify-center"}     > <img  id='bloc0_0' src="https://fs.appisyou.com/apps/moorea/a1710518843470.jpeg" className={ "w-screen h-screen absolute top-0 left-0 z-20   object-cover"}     alt="undefined" />
          <div id='bloc0_1'  className={ "z-30 text-6xl text-white font-bold text-right w-full py-10"}     >{`Moorea`} </div>
          <div id='bloc0_2'  className={ "text-white text-2xl z-30  text-right width=50vw"}   style = { {"width":"50vw"}}   >{`Le paradis existe... et il est sur Terre ! `} </div>
          <div id='bloc0_3'  className={ "absolute  width=100vw z-30 top-0 left-0 safearea flex flex-row justify-end items-end"}   style = { {"width":"100vw"}}   > <div id='bloc0_3_0'  className={ "m-4 rounded-full p-2 bg-primary_color text-white z-20 font-bold text-xs"}     >{`Déconnexion`} </div></div></div><div id='bloc1'  className={ " flex centre bg-primary_color fixed bottom-0 left-0 w-screen z-50 text-white safearea-bottom w-full  items-center justify-center"}     > <div id='bloc1_0'  className={ "pt-1 flex flex-row w-full justify-evenly pb-8"}     > <div id='bloc1_0_0x0'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc1_0_0x0_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc1_0_0x0_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc1_0_0x1'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc1_0_0x1_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc1_0_0x1_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc1_0_0x2'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc1_0_0x2_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc1_0_0x2_1'  className={ "text-xs"}     >{`Lien`} </div></div></div></div></>
  
}

export default Component28Page;
