// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component29Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full flex flex-row justify-between items-center bg-primary_color  fixed top-0 left-0 safearea-top z-40"}     > <FontAwesomeIcon  id='bloc0_0'  icon={ fa.faLongArrowLeft}      className={ "text-white p-4"}  />
          <div id='bloc0_1'  className={ "font-bold text-2xl text-white"}     >{`Titre Page`} </div>
          <img  id='bloc0_2' src="/profiles/ploy17/user.png" className={ "w-10 h-10 rounded-xl m-2   object-cover"}     alt="undefined" /></div><div id='bloc1'  className={ "w-full safearea-top p-10 opacity-0 text-2xl "}     >{`header`} </div><div id='bloc2'  className={ "w-full h-full bg-zinc-300  p-6 flex md:flex-row flex-col justify-center items-start text-zinc-900 py-8 relative m-40"}     > <img  id='bloc2_0' src="null" className={ "w-full h-full absolute -z-50  opacity-50 object-fill    object-cover"}     alt="undefined" />
          <div id='bloc2_1'  className={ "flex justify-between z-50"}     > <div id='bloc2_1_0'  className={ "flex  flex-col w-1/2 justify-center  items-center"}     > <div id='bloc2_1_0_0'  className={ "flex justify-center items-center text-4xl font-bold text-left my-2 px-4"}     >{`FaciaTherapie`} </div>
              <div id='bloc2_1_0_1'  className={ "flex justify-center  items-center text-4xl font-bold  my-2 px-4"}     >{`un soin innovant pour soigner`} </div></div>
            <div id='bloc2_1_1'  className={ "flex flex-col justify-start items-start w-1/2"}     > <div id='bloc2_1_1_0'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`L’anxiété, le stress ou la fatigue`} </div>
              <div id='bloc2_1_1_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Les douleurs chroniques telles que les lombalgies et les cervicalgies, les rhumatismes, l’arthrose, la fibromyalgie `} </div>
              <div id='bloc2_1_1_2'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Les lumbagos, les tendinites ou les torticolis `} </div>
              <div id='bloc2_1_1_3'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les troubles du transit `} </div>
              <div id='bloc2_1_1_4'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les céphalées, migraines et acouphènes`} </div>
              <div id='bloc2_1_1_5x0'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div>
      <div id='bloc2_1_1_5x1'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div>
      <div id='bloc2_1_1_5x2'  className={ "my-2 text-lg text-left max-w-5/6"}     >{`Les règles douloureuses.`} </div></div></div></div><div id='bloc3'  className={ " w-full bg-black bg-primary_color p-6 flex md:flex-row flex-col justify-center items-start text-white"}     > <div id='bloc3_0'  className={ "text-4xl font-bold text-left my-2 md:w-1/3 px-4"}     >{`La liberté de créer rapidement une appli Mobile qui vous ressemble, Modif2`} </div>
          <div id='bloc3_1'  className={ "flex flex-col justify-start items-start md:w-1/3"}     > <div id='bloc3_1_0'  className={ " my-2 text-lg text-left"}     >{`Créez à la fois une appli mobile et un site internet professionnel pour promouvoir votre entreprise et présenter votre travail en ligne. Lancez votre boutique en ligne ou votre blog - avec Wix, les possibilités sont infinies.`} </div>
            <div id='bloc3_1_1'       > <div id='bloc3_1_1_0'  className={ "border-b font-bold  border-white text-left inline "}     >{`Créer un site internet`} </div>
              <div id='bloc3_1_1_1'  className={ "mx-2"}     >{`->`} </div></div></div></div><div id='bloc4'  className={ "w-screen flex flex-col items-start my-10 overflow-hidden"}     > <div id='bloc4_0'  className={ "font-bold text-xl  p-2"}     >{`Commentaires`} </div>
          <div id='bloc4_1'  className={ "w-screen overflow-x-scroll "}     > <div id='bloc4_1_0'  className={ "flex flex-row"}     > <div id='bloc4_1_0_0x0'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x0_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x0_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x0_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x0_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x0_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x0_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x0_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x0_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x0_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x0_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x0_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x0_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x0_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x0_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x0_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x0_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x0_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x1'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x1_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x1_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x1_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x1_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x1_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x1_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x1_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x1_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x1_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x1_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x1_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x1_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x1_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x1_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x1_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x1_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x1_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x2'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x2_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x2_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x2_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x2_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x2_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x2_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x2_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x2_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x2_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x2_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x2_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x2_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x2_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x2_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x2_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x2_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x2_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x3'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x3_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x3_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x3_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x3_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x3_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x3_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x3_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x3_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x3_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x3_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x3_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x3_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x3_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x3_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x3_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x3_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x3_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x4'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x4_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x4_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x4_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x4_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x4_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x4_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x4_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x4_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x4_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x4_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x4_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x4_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x4_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x4_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x4_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x4_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x4_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x5'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x5_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x5_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x5_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x5_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x5_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x5_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x5_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x5_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x5_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x5_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x5_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x5_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x5_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x5_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x5_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x5_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x5_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x6'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x6_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x6_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x6_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x6_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x6_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x6_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x6_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x6_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x6_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x6_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x6_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x6_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x6_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x6_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x6_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x6_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x6_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x7'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x7_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x7_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x7_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x7_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x7_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x7_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x7_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x7_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x7_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x7_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x7_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x7_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x7_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x7_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x7_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x7_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x7_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x8'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x8_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x8_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x8_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x8_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x8_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x8_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x8_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x8_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x8_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x8_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x8_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x8_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x8_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x8_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x8_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x8_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x8_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x9'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x9_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x9_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x9_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x9_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x9_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x9_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x9_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x9_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x9_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x9_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x9_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x9_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x9_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x9_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x9_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x9_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x9_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x10'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x10_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x10_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x10_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x10_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x10_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x10_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x10_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x10_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x10_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x10_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x10_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x10_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x10_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x10_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x10_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x10_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x10_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div>
      <div id='bloc4_1_0_0x11'  className={ "w-64 p-1  w-2 h-2"}     > <div id='bloc4_1_0_0x11_0'  className={ "w-full h-40 overflow-hidden shadow flex flex-col border border-zinc-300 rounded-xl  p-2 justify-between items-start text-sm"}     > <div id='bloc4_1_0_0x11_0_0'  className={ "flex flex-col w-full items-start grow"}     > <div id='bloc4_1_0_0x11_0_0_0'  className={ "w-full flex flex-row items-center"}     > <div id='bloc4_1_0_0x11_0_0_0_0'  className={ "flex flex-row items-center"}     > <FontAwesomeIcon  id='bloc4_1_0_0x11_0_0_0_0_0'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x11_0_0_0_0_1'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x11_0_0_0_0_2'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x11_0_0_0_0_3'  icon={ fa.faStar}      className={ " w-2 h-2"}  />
                        <FontAwesomeIcon  id='bloc4_1_0_0x11_0_0_0_0_4'  icon={ fa.faStar}      className={ " w-2 h-2"}  /></div>
                      <div id='bloc4_1_0_0x11_0_0_0_1'  className={ "text-xs font-bold mx-2"}     >{`décembre 2023`} </div></div>
                    <div id='bloc4_1_0_0x11_0_0_1'  className={ "p-1 text-left grow overflow-hidden h-12"}     >{`Hôtes parfaits. Super endroit. Je reviendrais. Merci ???? ezfezgfuzegufzef zefiuzgefuzeguyzegf zeifuygzyefgyzuef zieufguzyegih iuhcuihzicuhsd`} </div>
                    <div id='bloc4_1_0_0x11_0_0_2'  className={ "underline text-xs font-bold p-1"}     >{`Afficher plus`} </div></div>
                  <div id='bloc4_1_0_0x11_0_1'  className={ "flex flex-row w-full"}     > <img  id='bloc4_1_0_0x11_0_1_0' src="undefined" className={ "h-10 w-10 rounded-full   object-cover"}     alt="undefined" />
                    <div id='bloc4_1_0_0x11_0_1_1'  className={ "flex flex-col items-start justify-center px-2"}     > <div id='bloc4_1_0_0x11_0_1_1_0'  className={ "font-bold text-sm"}     >{`Mark`} </div>
                      <div id='bloc4_1_0_0x11_0_1_1_1'  className={ "text-xs text-zinc-400"}     >{`Angleterre, Royaume-Uni`} </div></div></div></div></div></div></div></div><div id='bloc5'  className={ " flex centre bg-primary_color fixed bottom-0 left-0 w-screen z-50 text-white safearea-bottom w-full  items-center justify-center"}     > <div id='bloc5_0'  className={ "pt-1 flex flex-row w-full justify-evenly pb-8"}     > <div id='bloc5_0_0x0'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc5_0_0x0_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc5_0_0x0_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc5_0_0x1'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc5_0_0x1_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc5_0_0x1_1'  className={ "text-xs"}     >{`Lien`} </div></div>
    <div id='bloc5_0_0x2'  className={ "text-base flex flex-col p-2"}     > <FontAwesomeIcon  id='bloc5_0_0x2_0'  icon={ fa.faHomeAlt}        />
              <div id='bloc5_0_0x2_1'  className={ "text-xs"}     >{`Lien`} </div></div></div></div></>
  
}

export default Component29Page;
