import "../../App.css";

import {
  BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";


import { useEffect, useState } from "react";
import { getAuthUser } from "../../service/mftc-api";


import * as serviceWorkerRegistration from "../../serviceWorkerRegistration";
import NotFound from "./pages/notfound/NotFound";
import Component25Page from "./pages/Component25Page/Component25Page";
import Component28Page from "./pages/Component28Page/Component28Page";
import Component29Page from "./pages/Component29Page/Component29Page";
import Component30Page from "./pages/Component30Page/Component30Page";
import Component31Page from "./pages/Component31Page/Component31Page";


function AppClient() {



  const [authUser, setAuthUser] = useState(getAuthUser());









  useEffect(() => {
    serviceWorkerRegistration.updateRegister();
  }, []);


  const Init = () => {

      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.matchMedia("(display-mode: fullscreen)").matches;

if (window.navigator.standalone || isStandalone) {
                    return  !authUser ? <Component25Page/>    : <Component28Page/>
                          } else {
                    return  !authUser ? <Component25Page/>    : <Component28Page/>
                          }
  };


  return (

      <BrowserRouter>
        <Routes>
          <Route path="" element={<Init />} />
          <Route path="/" element={<Init />} />
          {
            }<Route path="/welcome" element={<Component25Page/>}/>
<Route path="/home" element={<Component28Page/>}/>
<Route path="/presentation" element={<Component29Page/>}/>
<Route path="/profile" element={<Component30Page/>}/>
<Route path="/home" element={<Component31Page/>}/>
{
          }
          
          <Route path="/404" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

  );
}

export default AppClient;



