// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component25Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-screen h-screen flex flex-col items-end justify-end overflow-hidden p-16 fixed top-0 left-0 z-50"}     > <div id='bloc0_0'  className={ "w-screen h-screen absolute top-0 left-0 z-20 overflow-scroll"}     > <div id='bloc0_0_0'  className={ "flex flex-inline"}     > <img  id='bloc0_0_0_0x0' src="https://fs.appisyou.com/apps/moorea/a1710518843470.jpeg" className={ "w-screen h-screen   object-cover"}     alt="undefined" /></div></div>
          <div id='bloc0_1'  className={ "absolute z-20 width=120vw marginLeft=-10vw  p-4 bottom-0 left-0 flex flex-col centre rounded-t-full bg-primary_color  items-center justify-center"}   style = { {"width":"120vw","marginLeft":"-10vw"}}   > <div id='bloc0_1_0'  className={ "horizontal center p-1 flex flex-row"}     > <div id='bloc0_1_0_0x0'  className={ "margin=1px  rounded-full h-2 w-2 bg-white bg-opacity-50"}   style = { {"margin":"1px"}}   > </div>
      <div id='bloc0_1_0_0x1'  className={ "margin=1px  rounded-full h-2 w-2 bg-white bg-opacity-50"}   style = { {"margin":"1px"}}   > </div>
      <div id='bloc0_1_0_0x2'  className={ "margin=1px  rounded-full h-2 w-2 bg-white bg-opacity-50"}   style = { {"margin":"1px"}}   > </div>
      <div id='bloc0_1_0_0x3'  className={ "margin=1px  rounded-full h-2 w-2 bg-white bg-opacity-50"}   style = { {"margin":"1px"}}   > </div>
      <div id='bloc0_1_0_0x4'  className={ "margin=1px  rounded-full h-2 w-2 bg-white bg-opacity-50"}   style = { {"margin":"1px"}}   > </div></div>
            <div id='bloc0_1_1'  className={ "text-white text-2xl z-30  text-base p-2"}     >{`Le paradis existe... et il est sur Terre ! `} </div>
            <div id='bloc0_1_2'  className={ "z-30 text-6xl text-white font-bold  p-6"}     >{`Moorea`} </div></div>
          <div id='bloc0_2'  className={ "absolute  width=100vw z-30 top-0 left-0 safearea flex flex-row justify-end items-end"}   style = { {"width":"100vw"}}   > <div id='bloc0_2_0'  className={ "m-4 rounded-full p-2 bg-primary_color text-white z-20 font-bold text-xs"}     >{`Connexion`} </div></div></div></>
  
}

export default Component25Page;
